import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/default-layout.js";
import DroneAnalyzer from 'applets/droneanalyzer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "drone-analyzer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#drone-analyzer",
        "aria-label": "drone analyzer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Drone Analyzer`}</h2>
    <p>{`Drone Analyzer is a tool that analyzes all the different harmonics and combination tones emitted by a `}<a parentName="p" {...{
        "href": "/learn/tanpuraworking-1/"
      }}>{`Drone`}</a>{` and visualizes the overall tuning system induced by the given Drone tuning. Use the tabs below to set the analysis parameters and the tunings of the drone and the scale you wish to analyze.`}</p>
    <DroneAnalyzer mdxType="DroneAnalyzer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      